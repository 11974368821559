














































import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// for internet explorer compatibility
import "core-js/features/object/values";
import "core-js/features/promise";
import "whatwg-fetch";
import {directions} from "./windrose.directions";

const svgRequest: Promise<HTMLElement> = fetch("resources/rose.svg")
    .then((svgResponse) => svgResponse.clone().text())
    .then((svgData) => {
      const parser = new DOMParser();
      return parser.parseFromString(svgData, "image/svg+xml").documentElement;
    });

@Component
export default class WindroseComponent extends Vue {

    @Prop({type: String}) public direction!: directions;

    private mounted() {
        svgRequest.then((rose) => {

            Object.values(directions).forEach((direction) => {
                if (direction !== directions.unknown) {
                    const branch = rose.querySelector(`#${direction}`) as SVGPathElement;
                    if (branch) {
                        branch.onclick = () => {
                            this.selectDirection(direction);
                        };
                    }
                }
            });

            this.$el.appendChild(rose);
        });
    }

    @Emit()
    private selectDirection(direction: directions) {
        return direction;
    }

}
