




import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getScoreDescription, ISignalLevels, ISignalRegion, signals} from "../lib/signals";

@Component
export default class RegionLevelDescriptionComponent extends Vue {
    @Prop() public regionId!: keyof ISignalRegion;
    @Prop() public level!: keyof ISignalLevels;

    private description: string = "";

    private mounted() {
        signals.onLoad(this.refreshDescription);
    }

    @Watch("regionId")
    @Watch("level")
    private refreshDescription() {
        this.description = "";
        signals.get().then((signalData) => {
            this.description = getScoreDescription(signalData.s[this.regionId].l[this.level].c);
        });
    }
}
