















import {Component, Vue, Watch} from "vue-property-decorator";
import {signals} from "../lib/signals";

@Component
export default class LastUpdateComponent extends Vue {

    private lastUpdate?: Date;
    private lastUpdateString: string = "";

    private mounted() {
        signals.onLoad(this.refreshDate);
    }

    @Watch("regionId")
    @Watch("level")
    private refreshDate() {
        this.lastUpdate = undefined;
        this.lastUpdateString = "";
        signals.get().then((signalData) => {
            this.lastUpdate = new Date(signalData.u);
            this.lastUpdateString = signalData.us;
        });
    }
}
