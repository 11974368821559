var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "felsampel-user-report",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c("h5", { staticClass: "wide" }, [
        _vm._v("Subjektiver Eindruck der Felsfeuchte")
      ]),
      _vm._v(" "),
      _c("label", [_vm._v("Name:")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userName,
            expression: "userName"
          }
        ],
        attrs: { placeholder: "Spitzname / Nickname / Pseudonym" },
        domProps: { value: _vm.userName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.userName = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mail,
            expression: "mail"
          }
        ],
        domProps: { value: _vm.mail },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.mail = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Datum:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.date,
                  expression: "date"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: _vm.moment().format("YYYY-MM-DD"),
                checked: _vm._q(_vm.date, _vm.moment().format("YYYY-MM-DD"))
              },
              on: {
                change: function($event) {
                  _vm.date = _vm.moment().format("YYYY-MM-DD")
                }
              }
            }),
            _vm._v(
              "\n                    " +
                _vm._s(_vm.moment().format("dddd, Do MMMM YYYY")) +
                "\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.date,
                  expression: "date"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: _vm
                  .moment()
                  .subtract(1, "days")
                  .format("YYYY-MM-DD"),
                checked: _vm._q(
                  _vm.date,
                  _vm
                    .moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")
                )
              },
              on: {
                change: function($event) {
                  _vm.date = _vm
                    .moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")
                }
              }
            }),
            _vm._v(
              "\n                     " +
                _vm._s(
                  _vm
                    .moment()
                    .subtract(1, "days")
                    .format("dddd, Do MMMM YYYY")
                ) +
                "\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.date,
                  expression: "date"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: _vm
                  .moment()
                  .subtract(2, "days")
                  .format("YYYY-MM-DD"),
                checked: _vm._q(
                  _vm.date,
                  _vm
                    .moment()
                    .subtract(2, "days")
                    .format("YYYY-MM-DD")
                )
              },
              on: {
                change: function($event) {
                  _vm.date = _vm
                    .moment()
                    .subtract(2, "days")
                    .format("YYYY-MM-DD")
                }
              }
            }),
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm
                    .moment()
                    .subtract(2, "days")
                    .format("dddd, Do MMMM YYYY")
                ) +
                "\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.date,
              expression: "date"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.date = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c(
            "option",
            {
              staticStyle: { display: "none" },
              domProps: { value: _vm.moment().format("YYYY-MM-DD") }
            },
            [_vm._v("\n            Früheres Datum auswählen\n        ")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              staticStyle: { display: "none" },
              domProps: {
                value: _vm
                  .moment()
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")
              }
            },
            [_vm._v("\n            Früheres Datum auswählen\n        ")]
          ),
          _vm._v(" "),
          _c(
            "option",
            {
              staticStyle: { display: "none" },
              domProps: {
                value: _vm
                  .moment()
                  .subtract(2, "days")
                  .format("YYYY-MM-DD")
              }
            },
            [_vm._v("\n            Früheres Datum auswählen\n        ")]
          ),
          _vm._v(" "),
          _vm._l(62, function(days) {
            return _c(
              "option",
              {
                domProps: {
                  value: _vm
                    .moment()
                    .subtract(days + 2, "days")
                    .format("YYYY-MM-DD")
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm
                        .moment()
                        .subtract(days + 2, "days")
                        .format("LL")
                    ) +
                    "\n        "
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("label", [_vm._v("Tageszeit:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.time,
                  expression: "time"
                }
              ],
              attrs: { type: "radio", value: "morning" },
              domProps: { checked: _vm._q(_vm.time, "morning") },
              on: {
                change: function($event) {
                  _vm.time = "morning"
                }
              }
            }),
            _vm._v("Morgens")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.time,
                  expression: "time"
                }
              ],
              attrs: { type: "radio", value: "forenoon" },
              domProps: { checked: _vm._q(_vm.time, "forenoon") },
              on: {
                change: function($event) {
                  _vm.time = "forenoon"
                }
              }
            }),
            _vm._v("Vormittags")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.time,
                  expression: "time"
                }
              ],
              attrs: { type: "radio", value: "noon" },
              domProps: { checked: _vm._q(_vm.time, "noon") },
              on: {
                change: function($event) {
                  _vm.time = "noon"
                }
              }
            }),
            _vm._v("Mittags")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.time,
                  expression: "time"
                }
              ],
              attrs: { type: "radio", value: "afternoon" },
              domProps: { checked: _vm._q(_vm.time, "afternoon") },
              on: {
                change: function($event) {
                  _vm.time = "afternoon"
                }
              }
            }),
            _vm._v("Nachmittags")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.time,
                  expression: "time"
                }
              ],
              attrs: { type: "radio", value: "evening" },
              domProps: { checked: _vm._q(_vm.time, "evening") },
              on: {
                change: function($event) {
                  _vm.time = "evening"
                }
              }
            }),
            _vm._v("Abends")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.time,
                  expression: "time"
                }
              ],
              attrs: { type: "radio", value: "night" },
              domProps: { checked: _vm._q(_vm.time, "night") },
              on: {
                change: function($event) {
                  _vm.time = "night"
                }
              }
            }),
            _vm._v("Nachts")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("Route")]),
      _vm._v(" "),
      _c("label", [_vm._v("Region:")]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.regions, function(regionDetails, rId) {
          return _c("li", [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.regionId,
                    expression: "regionId"
                  }
                ],
                attrs: { type: "radio" },
                domProps: { value: rId, checked: _vm._q(_vm.regionId, rId) },
                on: {
                  change: function($event) {
                    _vm.regionId = rId
                  }
                }
              }),
              _vm._v(
                "\n                " +
                  _vm._s(regionDetails.long_label) +
                  "\n            "
              )
            ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.summit,
            expression: "summit"
          }
        ],
        domProps: { value: _vm.summit },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.summit = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div", { staticClass: "subgrid" }, [
        _c("div", [
          _c("label", [_vm._v("Lage:")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.exposition,
                      expression: "exposition"
                    }
                  ],
                  attrs: { type: "radio", value: "top" },
                  domProps: { checked: _vm._q(_vm.exposition, "top") },
                  on: {
                    change: function($event) {
                      _vm.exposition = "top"
                    }
                  }
                }),
                _vm._v(
                  "\n                        Exponiert\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.exposition,
                      expression: "exposition"
                    }
                  ],
                  attrs: { type: "radio", value: "middle" },
                  domProps: { checked: _vm._q(_vm.exposition, "middle") },
                  on: {
                    change: function($event) {
                      _vm.exposition = "middle"
                    }
                  }
                }),
                _vm._v("\n                        Mittig\n                    ")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.exposition,
                      expression: "exposition"
                    }
                  ],
                  attrs: { type: "radio", value: "bottom" },
                  domProps: { checked: _vm._q(_vm.exposition, "bottom") },
                  on: {
                    change: function($event) {
                      _vm.exposition = "bottom"
                    }
                  }
                }),
                _vm._v(
                  "\n                        Tallage\n                    "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v("Abschattung:")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shading,
                      expression: "shading"
                    }
                  ],
                  attrs: { type: "radio", value: "sunny" },
                  domProps: { checked: _vm._q(_vm.shading, "sunny") },
                  on: {
                    change: function($event) {
                      _vm.shading = "sunny"
                    }
                  }
                }),
                _vm._v(
                  "\n                        Die Wand war sonnig\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shading,
                      expression: "shading"
                    }
                  ],
                  attrs: { type: "radio", value: "partial" },
                  domProps: { checked: _vm._q(_vm.shading, "partial") },
                  on: {
                    change: function($event) {
                      _vm.shading = "partial"
                    }
                  }
                }),
                _vm._v(
                  "\n                        Teils-teils\n                    "
                )
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shading,
                      expression: "shading"
                    }
                  ],
                  attrs: { type: "radio", value: "shady" },
                  domProps: { checked: _vm._q(_vm.shading, "shady") },
                  on: {
                    change: function($event) {
                      _vm.shading = "shady"
                    }
                  }
                }),
                _vm._v(
                  "\n                        Schattig (z.B. durch Bäume)\n                    "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("label", [_vm._v("Ausrichtung:")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.direction,
              expression: "direction"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.direction = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { selected: "", disabled: "", value: "" } }, [
            _vm._v("Bitte wählen.")
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "N" } }, [_vm._v("Nördlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "NE" } }, [_vm._v("Nordöstlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "E" } }, [_vm._v("Östlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "SE" } }, [_vm._v("Südöstlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "S" } }, [_vm._v("Südlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "SW" } }, [_vm._v("Südwestlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "W" } }, [_vm._v("Westlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "NW" } }, [_vm._v("Nordwestlich")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "?" } }, [_vm._v("Unbekannt")])
        ]
      ),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("felsampel-windrose", {
        attrs: { direction: _vm.direction },
        on: { "select-direction": _vm.selectDirection }
      }),
      _vm._v(" "),
      _c("h5", [_vm._v("Bewertung")]),
      _vm._v(" "),
      _c("label", { staticClass: "wide" }, [
        _vm._v("Feuchte an der Oberfläche")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "score-picker" }, [
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 1, checked: _vm._q(_vm.levelSurface, 1) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 1
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 2, checked: _vm._q(_vm.levelSurface, 2) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 2
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 3, checked: _vm._q(_vm.levelSurface, 3) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 3
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-5" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 4, checked: _vm._q(_vm.levelSurface, 4) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 4
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 5, checked: _vm._q(_vm.levelSurface, 5) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 5
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-8" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 6, checked: _vm._q(_vm.levelSurface, 6) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 6
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 7, checked: _vm._q(_vm.levelSurface, 7) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 7
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _vm._v("\n                    Unbewertet:\n                "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelSurface,
                  expression: "levelSurface"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 0, checked: _vm._q(_vm.levelSurface, 0) },
              on: {
                change: function($event) {
                  _vm.levelSurface = 0
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "wide" }, [
        _vm._v("Feuchte in Rissen (etwa eine Hand tief)")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "score-picker" }, [
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 1, checked: _vm._q(_vm.levelDeep, 1) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 1
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 2, checked: _vm._q(_vm.levelDeep, 2) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 2
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 3, checked: _vm._q(_vm.levelDeep, 3) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 3
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-5" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 4, checked: _vm._q(_vm.levelDeep, 4) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 4
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 5, checked: _vm._q(_vm.levelDeep, 5) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 5
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-8" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 6, checked: _vm._q(_vm.levelDeep, 6) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 6
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", { staticClass: "felsampel-user-feedback-score-10" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 7, checked: _vm._q(_vm.levelDeep, 7) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 7
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("label", [
            _vm._v("\n                    Unbewertet:\n                "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.levelDeep,
                  expression: "levelDeep"
                }
              ],
              attrs: { type: "radio" },
              domProps: { value: 0, checked: _vm._q(_vm.levelDeep, 0) },
              on: {
                change: function($event) {
                  _vm.levelDeep = 0
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("label", [_vm._v("Anmerkungen")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comment,
            expression: "comment"
          }
        ],
        attrs: {
          rows: "4",
          placeholder: "Wetter, Unerwartetes, Hinweise, ..."
        },
        domProps: { value: _vm.comment },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.comment = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "wide" }, [
        _vm._v(
          "\n        Beim Absenden eines Felsfeuchte-Berichtes werden auf unbestimmte Zeit\n        alle im Formular angegebenen Daten, sowie die sendende Internet-Protokoll-Adresse\n        und der Zeitpunkt des Sendens gespeichert.\n    "
        )
      ]),
      _vm._v(" "),
      _vm.missingFields.length !== 0
        ? _c("p", { staticClass: "wide" }, [
            _vm._v("\n        Bitte gib noch folgende Informationen an: "),
            _c("strong", [_vm._v(_vm._s(_vm.missingFields.join(", ")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "submit",
          value: "Absenden",
          disabled: !_vm.canSubmit || _vm.asyncInProgress
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("E-Mail: "),
      _c("span", { staticClass: "optional" }, [_vm._v("(Optional)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Gipfel: "),
      _c("span", { staticClass: "optional" }, [_vm._v("(Optional)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }