
























































































































































































































































































































































































































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";


@Component
export default class RouterComponent extends Vue {

    private showLongExplanation: boolean = JSON.parse(localStorage.showLongExplanation || "false");
    private showReportForm: boolean = JSON.parse(localStorage.showReportForm || "false");

    private deobfuscate(s: string): string {
        return atob(s);
    }

    get herbstzeit(): boolean {
        const now = new Date();
        return (now.getMonth() + 1 === 10 && now.getDate() >= 23)
            || (now.getMonth() + 1 === 11)
            || (now.getMonth() + 1 === 12 && now.getDate() <= 22);
    }

    @Watch("$route")
    private onRouteChange() {
        if (this.$router.currentRoute.params.section === "presentation") {
            this.showLongExplanation = true;
        }
    }

    @Watch("showLongExplanation")
    private showLongExplanationChanged(newValue: boolean) {
        localStorage.showLongExplanation = JSON.stringify(newValue);
    }

    @Watch("showReportForm")
    private showReportFormChanged(newValue: boolean) {
        localStorage.showReportForm = JSON.stringify(newValue);
    }

}

