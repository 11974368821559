




import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import {FelsampelHistoryChart, Periods} from "../lib/history";
import {IRegionList} from "../lib/regions";
import {ISignalLevels} from "../lib/signals";

@Component
export default class HistoryComponent extends Vue {

    @Prop({type: Number}) private period!: Periods;
    @Prop({type: String}) private regionId!: keyof IRegionList;
    @Prop({type: String, default: "me"}) private levelId!: keyof ISignalLevels;

    private chart!: FelsampelHistoryChart;
    private reloadIntervall: number = 60 * 60 * 8;  // 8 hours

    private mounted() {
        this.chart = new FelsampelHistoryChart(this.$el as HTMLCanvasElement, this.period);
        this.updateChart();
        setInterval(this.updateChart, this.reloadIntervall * 1000);
    }

    @Watch("regionId")
    @Watch("levelId")
    private updateChart() {
        if (this.regionId && this.levelId) {
            this.chart.clearDatasets();
            this.chart.loadDatasets(this.regionId, this.levelId);
        }
    }

}
