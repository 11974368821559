


















































































































































































































































































































































































import * as moment from "moment";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {regions} from "../lib/regions";
import {IRegions} from "../lib/signals";
import WindroseComponent from "./windrose.vue";
import {directions} from "./windrose.directions";

@Component
export default class UserReportComponent extends Vue {

    private asyncInProgress: boolean = false;

    private regions = regions;

    private userName: string = "";
    private mail: string = "";
    private date: string = moment().format("YYYY-MM-DD");
    private time: string = "";
    private regionId: keyof IRegions = "";
    private summit: string = "";
    private direction: string = "?";
    private exposition: string = "";
    private shading: string = "";
    private levelSurface: number = 0;
    private levelDeep: number = 0;
    private comment: string = "";

    private moment(v: any): moment.Moment {
        return moment(v);
    }

    get missingFields(): string[] {

        const missing = [];

        if (this.userName.length < 1) {
            missing.push("Name");
        }

        if (!this.date) {
            missing.push("Datum");
        }

        if (!this.time) {
            missing.push("Tageszeit");
        }

        if (!this.regionId) {
            missing.push("Region");
        }

        if (!this.exposition) {
            missing.push("Lage");
        }

        if (!this.shading) {
            missing.push("Abschattung");
        }

        if (!this.direction) {
            missing.push("Ausrichtung");
        }

        if (!this.levelSurface && !this.levelDeep && this.comment.length < 1) {
            missing.push("Bewertung oder Anmerkung");
        }

        return missing;
    }

    get canSubmit() {
        return this.missingFields.length === 0;
    }

    private onSubmit() {
        this.asyncInProgress = true;
        fetch("https://felsampel.bergsteigerbund.de/measurements/v1/report", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user: this.userName,
                mail: this.mail,
                date: this.date,
                time: this.time,
                region: this.regionId,
                summit: this.summit || undefined,
                direction: this.direction,
                exposition: this.exposition,
                shading: this.shading,
                level_deep: this.levelDeep || undefined,
                level_surface: this.levelSurface || undefined,
                comment: this.comment || undefined,
            }),
        }).then((response) => {
            this.asyncInProgress = false;
            if (response.ok) {
                this.summit = "";
                this.direction = "?";
                this.exposition = "";
                this.shading = "";
                this.levelDeep = 0;
                this.levelSurface = 0;
                this.comment = "";
                alert(`Vielen Dank für deine Meldung.`);
            } else {
                response.text().then((reason: string) => {
                    alert(`Das senden ist leider Fehlgeschlagen. \n` +
                          `Bitte wende dich per E-Mail an uns.\n` +
                          `\n` +
                          `${reason}`);
                });
            }
        }).catch((reason) => {
            this.asyncInProgress = false;
            alert(`Das senden ist leider Fehlgeschlagen. \n` +
                  `Bitte wende dich per E-Mail an uns.\n` +
                  `\n` +
                  `${reason}`);
        });
    }

    private selectDirection(direction: directions) {
        this.direction = direction;
    }

    @Watch("userName")
    private userNameChanged(newValue: string) {
        localStorage.userName = JSON.stringify(newValue);
    }

    @Watch("mail")
    private mailChanged(newValue: string) {
        localStorage.mail = JSON.stringify(newValue);
    }

    private mounted() {
        this.userName = JSON.parse(localStorage.userName || '""');
        this.mail = JSON.parse(localStorage.mail || '""');
    }

}
