

















































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IRegions} from "../lib/signals";
import {ISignalLevels} from "../lib/signals";
import {signals} from "../lib/signals";
import {expositionNames} from "../lib/signals";
import {shadingNames} from "../lib/signals";
import {Periods} from "../lib/history";
import {regions} from "../lib/regions";


@Component
export default class AppComponent extends Vue {

    private readonly pageReloadIntervall: number = 60 * 60 * 24 * 7;  // 7 days
    private readonly dataReloadIntervall: number = 60 * 15;  // 15 minutes
    private showDailyHistory: boolean = false;
    private showHourlyHistory: boolean = true;
    private expositionNames = expositionNames;
    private shadingNames = shadingNames;
    private regions = regions;
    private Periods = Periods;

    @Prop({type: String, default: undefined}) regionId!: keyof IRegions;
    @Prop({type: String, default: "me"}) levelId!: keyof ISignalLevels;

    private selectRegion(regionId: number) {
        this.$router.push(`/region-${regionId}/me/region`);
    }

    private selectLevelAndRegion(regionId: keyof IRegions, levelId: keyof ISignalLevels) {
        this.$router.push({
            name: "region",
            params: {
                regionId: String(regionId),
                levelId: String(levelId),
                section: "region",
            },
        });
    }

    private selectLevel(regionId: keyof IRegions, levelId: keyof ISignalLevels) {
        this.$router.push({
            name: "region",
            params: {
                regionId: String(regionId),
                levelId: String(levelId),
            },
        });
    }

    private mounted() {
        setInterval(this.forceReload, this.dataReloadIntervall * 1000);
        setInterval(() => window.location.reload(), this.pageReloadIntervall * 1000);
    }

    private forceReload() {
        signals.refresh();
    }

    get exposition(): string {
        if (this.levelId) {
            return this.levelId[0];
        }
        return "";
    }

    set exposition(value) {
        if (this.levelId) {
            this.levelId = `${value}${this.levelId[1]}` as keyof ISignalLevels;
        }
    }

    get shading(): string {
        if (this.levelId) {
            return this.levelId[1];
        }
        return "";
    }

    set shading(value) {
        if (this.levelId) {
            this.levelId = `${this.levelId[0]}${value}` as keyof ISignalLevels;
        }
    }

    @Watch("levelId")
    private bla(newValue: keyof ISignalLevels) {
        if (this.regionId) {
            this.$router.push({
                name: "region",
                params: {
                    regionId: String(this.regionId),
                    levelId: newValue,
                },
            });
        }
    }

}

