var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "felsampel-windrose",
    class: {
      "felsampel-windrose-highlight-N": _vm.direction === "N",
      "felsampel-windrose-highlight-NE": _vm.direction === "NE",
      "felsampel-windrose-highlight-E": _vm.direction === "E",
      "felsampel-windrose-highlight-SE": _vm.direction === "SE",
      "felsampel-windrose-highlight-S": _vm.direction === "S",
      "felsampel-windrose-highlight-SW": _vm.direction === "SW",
      "felsampel-windrose-highlight-W": _vm.direction === "W",
      "felsampel-windrose-highlight-NW": _vm.direction === "NW"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }