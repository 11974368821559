import "../style/map.scss";
import {FelsampelPrognosis} from "./prognosis";
import {IRegion, regions} from "./regions";
import {getScoreDescription, getScoreNumber, IRegions, ISignalRegion, signals} from "./signals";
import {FelsampelSummit, levelSelectCallback, summitLevels} from "./summit";

// leaflet.js
import * as L from "leaflet";
import {LeafletMouseEvent} from "leaflet";
import "leaflet/dist/leaflet.css";

// for internet explorer compatibility
import "classlist-polyfill";

export type regionSelectCallback = (regionId: keyof IRegions,
                                    regionDetails: IRegion,
                                    regionSignals: ISignalRegion) => void;

export interface IFelsampelAttributes {
    zoom?: number;
    backgroundZoomOffset?: number;
    showAreas?: boolean;
    showNames?: boolean;
    showLabelPane?: boolean;
    showInfoPanels?: boolean;
    showAttribution?: boolean;
    showScoreBar?: boolean;
    showPrognosisBar?: boolean;
    areaBorderWeight?: number;
    logClicks?: boolean;
    regionSelectCallback?: regionSelectCallback;
    levelSelectCallback?: levelSelectCallback;
}

export class FelsampelMap implements IFelsampelAttributes {

    public zoom: number = 11;
    public backgroundZoomOffset: number = 0;
    public showAreas: boolean = true;
    public showNames: boolean = false;
    public showLabelPane: boolean = false;
    public showInfoPanels: boolean = false;
    public showAttribution: boolean = true;
    public showScoreBar: boolean = false;
    public showPrognosisBar: boolean = true;
    public areaBorderWeight: number = 3;
    public logClicks: boolean = false;
    public regionSelectCallback?: regionSelectCallback = undefined;
    public levelSelectCallback?: levelSelectCallback = undefined;

    private map?: L.Map;
    private readonly element: HTMLElement;

    constructor(element: HTMLElement) {
        this.element = element;
    }

    public draw() {

        const backgroundLayer = L.tileLayer(
            "https://felsampel.bergsteigerbund.de/tiles/" +
            "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png",
            {
                attribution: "Map tiles by <a href=\"http://stamen.com\">Stamen Design</a>, " +
                    "<a href=\"http://creativecommons.org/licenses/by/3.0\">CC BY 3.0</a>",
                subdomains: "abcd",
                opacity: .5,
                minZoom: 1,
                maxZoom: 16,
                tileSize: 256 / Math.pow(2, this.backgroundZoomOffset || 0),
                zoomOffset: this.backgroundZoomOffset,
            });

        this.map = L.map(this.element, {

            // make map noninteractive
            trackResize: false,
            boxZoom: false,
            doubleClickZoom: false,
            keyboard: false,
            dragging: false,
            zoomControl: false,
            scrollWheelZoom: false,

            // mobile device hacks
            touchZoom: false,
            tap: false,

            // map positioning
            center: [50.899, 14.205],
            zoom: this.zoom,
            minZoom: this.zoom,
            maxZoom: this.zoom,

            // layers and attribution
            attributionControl: false,
            layers: [
                backgroundLayer,
            ],

        });

        // create Label Pane
        if (this.showLabelPane) {
            this.map.createPane("labels");
            const labelPane = this.map.getPane("labels");
            if (labelPane) {
                labelPane.style.zIndex = "650";
                labelPane.style.pointerEvents = "none";
            }
        }

        // until all tiles are loaded the sizing may be wrong
        backgroundLayer.on("load", this.drawFeatures.bind(this, this.map));

        // helper for map corrections
        if (this.logClicks) {
            this.map.on("click", (event) => {

                function round(num: number) {
                    return Math.round(num * 1000) / 1000;
                }

                // tslint:disable-next-line:no-console
                console.log(`[${round((event as LeafletMouseEvent).latlng.lat)},` +
                            ` ${round((event as LeafletMouseEvent).latlng.lng)}]`);

            });
        }

    }

    public clear() {
        if (this.map) {
            this.map.remove();
        }
    }

    private drawFeatures(map: L.Map) {

        if (this.showAttribution) {
            this.drawAttribution(map);
        }

        if (this.showAreas) {
            this.drawAreas(map);
        }

        if (this.showNames) {
            this.drawNameLabels(map);
        }

        if (this.showScoreBar) {
            this.drawScoreBar(map);
        }

        if (this.showInfoPanels) {
            this.drawNameInfoPanels(map);
        }

    }

    private drawAreas(map: L.Map) {

        signals.get().then((signalData) => {

            for (const regionId in signalData.s) {

                // area
                if (regions[regionId].area) {

                    const classes = [
                        "felsampel-area",
                        `felsampel-area-score-${getScoreNumber(signalData.s[regionId].a)}`];

                    if (this.regionSelectCallback) {
                        classes.push("clickable");
                    }

                    L.polygon(regions[regionId].area,
                        {
                            color: "white",
                            weight: this.areaBorderWeight,
                            className: classes.join(" "),
                            fillOpacity: .7,
                        })
                        .on("click", () => {
                            if (typeof this.regionSelectCallback === "function") {
                                this.regionSelectCallback(regionId, regions[regionId], signalData.s[regionId]);
                            }
                        })
                        .addTo(map);

                }
            }

        });

    }

    private drawAttribution(map: L.Map) {

        signals.get().then((signalData) => {

            L.control.attribution({
                prefix: `Datenstand: ${signalData.us}`,
            }).addTo(map);

        });
    }

    private drawNameLabels(map: L.Map) {

        signals.get().then((signalData) => {

            for (const regionId in signalData.s) {

                if (regions[regionId].name_position) {
                    L.popup(
                        {
                            closeOnClick: false,
                            closeButton: false,
                            autoClose: false,
                            autoPan: false,
                            className: "name",
                        })
                        .setLatLng(regions[regionId].name_position)
                        .setContent(regions[regionId].region_label)
                        .openOn(map)
                        .getElement();

                    if (regions[regionId].name_arrow) {
                        L.polyline(regions[regionId].name_arrow,
                            {
                                color: "black",
                                weight: 1.5,
                                className: "felsampel-name-arrow",
                            })
                            .addTo(map);
                        L.circle(regions[regionId].name_arrow[regions[regionId].name_arrow.length - 1],
                            {
                                className: "felsampel-name-arrow-point",
                                color: "black",
                                radius: 150,
                            }).addTo(map);
                    }
                }

            }

        });
    }

    private drawNameInfoPanels(map: L.Map) {

        signals.get().then((signalData) => {

            for (const regionId in signalData.s) {

                if (regions[regionId].info_position) {

                    const container = document.createElement("DIV");

                    const header = document.createElement("H3");
                    header.textContent = regions[regionId].region_label;
                    container.appendChild(header);

                    if (typeof this.regionSelectCallback === "function") {
                        header.classList.add("clickable");
                        header.addEventListener("click", (event) => {
                            if (typeof this.regionSelectCallback === "function") {
                                this.regionSelectCallback(regionId, regions[regionId], signalData.s[regionId]);
                            }
                        });
                    }

                    const row = document.createElement("DIV");
                    row.classList.add("felsampel-summit-row");
                    container.appendChild(row);

                    for (const [level, title] of [
                            ["b", "Tallage"],
                            ["m", "Mittig"],
                            ["t", "Exponiert"],
                        ]) {
                        const summit = new FelsampelSummit(row, title);
                        if (typeof this.levelSelectCallback === "function") {
                            summit.onClickCallback = this.levelSelectCallback;
                        }
                        summit.draw(regionId, level as summitLevels);
                    }

                    if (this.showPrognosisBar) {

                        const prognosis = document.createElement("DIV");
                        new FelsampelPrognosis(prognosis).draw(regionId, "me");
                        container.appendChild(prognosis);

                        if (typeof this.regionSelectCallback === "function") {
                            prognosis.classList.add("clickable");
                            prognosis.addEventListener("click", (event) => {
                                if (typeof this.regionSelectCallback === "function") {
                                    this.regionSelectCallback(regionId, regions[regionId], signalData.s[regionId]);
                                }
                            });
                        }
                    }


                    L.popup(
                        {
                            closeOnClick: false,
                            closeButton: false,
                            autoClose: false,
                            className: "info-panel",
                        })
                        .setLatLng(regions[regionId].info_position)
                        .setContent(container)
                        .openOn(map)
                        .getElement();

                }
            }
        });

    }

    private drawScoreBar(map: L.Map) {

        map.addControl(new (L.Control.extend({
            onAdd() {
                const control = document.createElement("DIV");
                control.classList.add("felsampel-score-bar");
                const container = document.createElement("DIV");
                container.classList.add("felsampel-score-bar-container");
                control.appendChild(container);

                const header = document.createElement("DIV");
                header.classList.add("felsampel-score-bar-header");
                header.innerText = "Klettereignung bzgl. Feuchtigkeit in 3h";
                container.appendChild(header);

                const labels = document.createElement("DIV");
                labels.classList.add("felsampel-score-bar-labels");
                container.appendChild(labels);

                const leftLabel = document.createElement("LABEL");
                leftLabel.innerText = "Gut";
                leftLabel.classList.add("left-label");
                labels.appendChild(leftLabel);

                const rightLabel = document.createElement("LABEL");
                rightLabel.innerText = "Schlecht";
                rightLabel.classList.add("right-label");
                labels.appendChild(rightLabel);

                const boxes = document.createElement("DIV");
                boxes.classList.add("felsampel-score-bar-boxes");
                container.appendChild(boxes);

                for (let score = 0; score <= 10; score++) {
                    const box = document.createElement("DIV");
                    box.classList.add(`felsampel-score-${score}-block`);
                    box.title = getScoreDescription((10 - score) / 10);
                    boxes.appendChild(box);
                }

                return control;
            },
            onRemove() {
                // Nothing to do here
            },
        }) as any)({position: "bottomright"}) as L.Control);

    }

}
