type Point = [number, number];

export interface IRegion {
    long_label: string;
    region_label: string;
    name_position: Point;
    name_arrow: Point[];
    info_position: Point;
    area: Point[];
}

export interface IRegionList {
    [key: string]: IRegion;
}

export const regions: IRegionList = {

    0: {
        long_label: "Schrammsteine und Schmilka",
        region_label: "Schrammsteine, \nSchmilka",
        name_position: [50.940, 14.300],
        name_arrow: [
            [50.944, 14.230],
            [50.910, 14.230],
        ],
        info_position: [50.860, 14.203],
        area: [
            [50.930, 14.191],
            [50.930, 14.216],
            [50.915, 14.253],
            [50.895, 14.268],
            [50.889, 14.254],
            [50.887, 14.236],
            [50.894, 14.230],
            [50.901, 14.222],
            [50.909, 14.195],
            [50.913, 14.178],
        ],
    },

    1: {
        long_label: "Affensteine und kleiner Zschand",
        region_label: "Affensteine, \nKl. Zschand",
        name_position: [50.810, 14.325],
        name_arrow: [
            [50.854, 14.276],
            [50.905, 14.276],
        ],
        info_position: [50.921, 14.203],
        area: [
            [50.915, 14.253],
            [50.917, 14.275],
            [50.915, 14.296],
            [50.892, 14.284],
            [50.895, 14.268],
        ],
    },

    2: {
        long_label: "Wildensteiner Gebiet",
        region_label: "Wildenstein",
        name_position: [50.925, 14.415],
        name_arrow: [
            [50.942, 14.355],
            [50.942, 14.330],
            [50.928, 14.290],
            [50.928, 14.276],
        ],
        info_position: [50.914, 14.342],
        area: [
            [50.930, 14.216],
            [50.941, 14.265],
            [50.941, 14.308],
            [50.931, 14.350],
            [50.915, 14.296],
            [50.917, 14.275],
            [50.915, 14.253],
        ],
    },

    3: {
        long_label: "Großer Zschand und Hinterhermsdorf",
        region_label: "       Gr. Zschand, \nHinterhermsdorf",
        name_position: [50.860, 14.450],
        name_arrow: [
            [50.890, 14.360],
            [50.890, 14.338],
            [50.909, 14.338],
        ],
        info_position: [50.853, 14.342],
        area: [
            [50.915, 14.296],
            [50.931, 14.350],
            [50.921, 14.390],
            [50.898, 14.390],
            [50.895, 14.374],
            [50.898, 14.352],
            [50.892, 14.352],
            [50.882, 14.303],
            [50.885, 14.290],
            [50.892, 14.284],
        ],
    },

    4: {
        long_label: "Rathen, Wehlen und Brand",
        region_label: "Rathen, \nWehlen, \nBrand",
        name_position: [50.930, 14.025],
        name_arrow: [
            [50.97, 14.015],
            [50.97, 14.050],
        ],
        info_position: [50.928, 14.063],
        area: [
            [50.983, 14.029],
            [50.983, 14.123],
            [50.959, 14.168],
            [50.942, 14.168],
            [50.934, 14.139],
            [50.934, 14.110],
            [50.941, 14.104],
            [50.959, 14.071],
            [50.959, 14.019],
        ],
    },

    5: {
        long_label: "Gebiet der Steine",
        region_label: "Gebiet der Steine",
        name_position: [50.883, 14.005],
        name_arrow: [
            [50.9, 14.080],
            [50.9, 14.120],
        ],
        info_position: [50.867, 14.063],
        area: [
            [50.934, 14.007],
            [50.954, 14.016],
            [50.954, 14.068],
            [50.938, 14.098],
            [50.934, 14.101],
            [50.926, 14.101],
            [50.905, 14.192],
            [50.897, 14.218],
            [50.887, 14.228],
            [50.875, 14.228],
            [50.851, 14.208],
            [50.831, 14.124],
            [50.885, 14.063],
            [50.885, 14.007],
        ],
    },

    6: {
        long_label: "Bielatal",
        region_label: "Bielatal",
        name_position: [50.828, 13.970],
        name_arrow: [
            [50.845, 14.003],
            [50.845, 14.020],
        ],
        info_position: [50.810, 14.10],
        area: [
            [50.885, 14.007],
            [50.885, 14.063],
            [50.831, 14.124],
            [50.819, 14.079],
            [50.812, 14.079],
            [50.806, 14.060],
            [50.806, 14.039],
            [50.820, 14.007],
        ],
    },

};
