import "../style/summit.scss";

import {IRegionList} from "./regions";
import {getScoreNumber, IRegions, ISignalLevels, ISignalRegion, signals} from "./signals";

// for internet explorer compatibility
import "classlist-polyfill";
import "core-js/features/promise";
import "whatwg-fetch";

export type levelSelectCallback = (regionId: keyof IRegions,
                                   levelId: keyof ISignalLevels) => void;

export enum summitLevels {
    t = "t",  // top
    m = "m",  // middle
    b = "b",  // bottom
}

// Get the summit svg as string, because some browsers allow styling SVG
// via CSS only if the element is not included with src=… but as source code.
const svgRequest: Promise<HTMLElement> = fetch("resources/summit.svg")
    .then((svgResponse) => svgResponse.clone().text())
    .then((svgData) => {
        const parser = new DOMParser();
        return parser.parseFromString(svgData, "image/svg+xml").documentElement;
});

export class FelsampelSummit {

    // noinspection JSMethodCanBeStatic
    private static getSignalClassNames(data: ISignalRegion, level: summitLevels) {

        if (data.l) {
            if (level === "t") {
                return [
                    `felsampel-exposed-score-${getScoreNumber(data.l.te.c)}`,
                    `felsampel-shady-score-${getScoreNumber(data.l.ts.c)}`,
                ];
            } else if (level === "m") {
                return [
                    `felsampel-exposed-score-${getScoreNumber(data.l.me.c)}`,
                    `felsampel-shady-score-${getScoreNumber(data.l.ms.c)}`,
                ];
            } else if (level === "b") {
                return [
                    `felsampel-exposed-score-${getScoreNumber(data.l.be.c)}`,
                    `felsampel-shady-score-${getScoreNumber(data.l.bs.c)}`,
                ];
            }
        }

        return [];

    }

    public onClickCallback?: levelSelectCallback;

    public readonly element: HTMLElement;
    public readonly title?: string;

    constructor(element: HTMLElement, title?: string) {
        this.element = element;
        this.title = title;
    }

    public clear() {
        this.element.innerHTML = "";
    }

    public draw(regionId?: keyof IRegionList, level?: summitLevels) {

        if (!regionId || !level) {
            this.clear();
        } else {
            this.element.appendChild(this.generate(regionId, level, this.title));
        }

    }

    private generate(regionId: keyof IRegions, level: summitLevels, title?: string) {

        const container = document.createElement("DIV");
        container.classList.add("felsampel-signal");

        if (title) {
            const label = document.createElement("LABEL");
            label.textContent = title;
            container.appendChild(label);
            if (this.onClickCallback) {
                label.classList.add("clickable");
                label.addEventListener("click", (event) => {
                    if (this.onClickCallback) {
                        this.onClickCallback(regionId, `${level[0]}e` as keyof ISignalLevels);
                    }
                });
            }
        }

        const border = document.createElement("DIV");
        border.classList.add("felsampel-signal-border");
        container.appendChild(border);

        svgRequest.then((svgElement) => {
            const summit = svgElement.cloneNode(true) as HTMLElement;

            if (this.onClickCallback) {
                svgElement.classList.add("clickable-sides");
            }

            const summitExposedRock = summit.querySelector("#exposed-rock") as SVGPathElement;
            if (summitExposedRock) {
                summitExposedRock.onclick = (event) => {
                    if (this.onClickCallback) {
                        this.onClickCallback(regionId, `${level[0]}e` as keyof ISignalLevels);
                    }
                };
            }

            const summitShadyRock = summit.querySelector("#shady-rock") as SVGPathElement;
            if (summitShadyRock) {
                summitShadyRock.onclick = (event) => {
                    if (this.onClickCallback) {
                        this.onClickCallback(regionId, `${level[0]}s` as keyof ISignalLevels);
                    }
                };
            }

            border.appendChild(summit);
            signals.get().then((signalData) => {
                FelsampelSummit.getSignalClassNames(signalData.s[regionId], level).forEach((className) => {
                    summit.classList.add(className);
                });
            });
        });

        return container;

    }

}
