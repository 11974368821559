// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"score-0-color": "#47f425",
	"score-1-color": "#6af425",
	"score-2-color": "#8cf425",
	"score-3-color": "#aff425",
	"score-4-color": "#d1f425",
	"score-5-color": "#f4f425",
	"score-6-color": "#f4d125",
	"score-7-color": "#f4af25",
	"score-8-color": "#f48c25",
	"score-9-color": "#f46a25",
	"score-10-color": "#f44725"
};
export default ___CSS_LOADER_EXPORT___;
