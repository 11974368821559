




import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IRegions, ISignalLevels, signals} from "../lib/signals";
import {FelsampelSummit, summitLevels, levelSelectCallback} from "../lib/summit";

@Component
export default class SummitComponent extends Vue {

    @Prop({type: String}) public regionId!: string;
    @Prop({type: String}) public level!: summitLevels;
    @Prop({type: Function}) public onClick!: levelSelectCallback;

    private summit!: FelsampelSummit;

    @Watch("regionId")
    @Watch("level")
    public drawSummit() {
        this.summit.clear();
        this.summit.draw(this.regionId, this.level);
    }

    private mounted() {
        this.summit = new FelsampelSummit(this.$el as HTMLElement);
        this.summit.onClickCallback = this.onClick;
        signals.onLoad(this.drawSummit);
    }

}
