import "../style/prognosis.scss";
import {IRegionList} from "./regions";
import {getScoreDescription, getScoreNumber, ISignalLevels, levelNames, signals} from "./signals";

// moment.js
import * as moment from "moment";

// for internet explorer compatibility
import "classlist-polyfill";

export class FelsampelPrognosis {

    public dateFormat: string = "dd";
    public showPrognosisLabel: boolean = true;

    private readonly element: HTMLElement;

    constructor(element: HTMLElement) {
        this.element = element;
    }

    public clear() {
        this.element.innerHTML = "";
    }

    public draw(regionId: keyof IRegionList, levelId: keyof ISignalLevels = "me") {

        if (!regionId) {
            this.clear();
        } else {
            this.element.appendChild(this.generate(regionId, levelId));
        }

    }

    private generate(regionId: keyof IRegionList,
                     levelId: keyof ISignalLevels) {

        const prognosisContainer = document.createElement("DIV");
        const prognosisBar = document.createElement("DIV");
        prognosisContainer.classList.add("felsampel-prognosis-bar-container");
        prognosisBar.classList.add("felsampel-prognosis-bar");

        if (this.showPrognosisLabel) {
            const prognosisLabel = document.createElement("LABEL");
            prognosisLabel.innerText = "Prognose";
            prognosisContainer.appendChild(prognosisLabel);
        }

        signals.get().then((signalData) => {
            const regionSignals = signalData.s[regionId];
            for (let relativeHour = 0; relativeHour < regionSignals.l[levelId].p.length; relativeHour++) {
                const absoluteHour = moment(new Date(signalData.u)).add(relativeHour, "hour");
                const prognosisHour = document.createElement("DIV");
                if (relativeHour < 84 && absoluteHour.hour() % 24 === 0) {
                    const prognosisHourLabel = document.createElement("LABEL");
                    prognosisHourLabel.classList.add("felsampel-prognosis-bar-hour-label");
                    prognosisHourLabel.innerText = absoluteHour.format(this.dateFormat);
                    prognosisHour.appendChild(prognosisHourLabel);
                } else {
                    prognosisHour.innerHTML = "&nbsp;";
                }
                prognosisHour.classList.add("felsampel-prognosis-bar-hour");
                const prognosisHourScore = getScoreNumber(regionSignals.l[levelId].p[relativeHour]);
                prognosisHour.classList.add(`felsampel-prognosis-bar-score-${prognosisHourScore}`);
                prognosisHour.setAttribute("day", `${absoluteHour.date()}`);
                prognosisHour.setAttribute("hour", `${absoluteHour.hour()}`);
                prognosisHour.setAttribute("offset", `${relativeHour}`);

                const levelDescription = levelNames[levelId];
                const scoreDescription = getScoreDescription(regionSignals.l[levelId].p[relativeHour]);
                prognosisHour.setAttribute("title",
                    `${levelDescription}, ${absoluteHour.format("LL, H")} Uhr: ${scoreDescription}`);

                prognosisBar.appendChild(prognosisHour);
            }
        });

        prognosisContainer.appendChild(prognosisBar);

        return prognosisContainer;
    }

}
