




import { Component, Prop, Vue } from "vue-property-decorator";
import {FelsampelMap, IFelsampelAttributes, regionSelectCallback} from "../lib/map";
import {signals} from "../lib/signals";
import {levelSelectCallback} from "../lib/summit";

@Component
export default class MapComponent extends Vue implements IFelsampelAttributes {

    @Prop({type: Number}) public areaBorderWeight!: number;
    @Prop({type: Number}) public backgroundZoomOffset!: number;
    @Prop({type: Function}) public regionSelectCallback!: regionSelectCallback;
    @Prop({type: Function}) public levelSelectCallback!: levelSelectCallback;
    @Prop({type: Number}) public reloadIntervall!: number;
    @Prop({type: Boolean, default: undefined}) public showAttribution!: boolean;
    @Prop({type: Boolean, default: undefined}) public showInfoPanels!: boolean;
    @Prop({type: Boolean, default: undefined}) public showLabelPane!: boolean;
    @Prop({type: Boolean, default: undefined}) public showScoreBar!: boolean;
    @Prop({type: Boolean, default: undefined}) public showNames!: boolean;
    @Prop({type: Number}) public zoom!: number;

    private map!: FelsampelMap;

    public drawMap() {
        this.map.clear();
        this.map.draw();
    }

    private mounted() {
        this.map = new FelsampelMap(this.$el as HTMLElement);

        if (this.areaBorderWeight !== undefined) {
            this.map.areaBorderWeight = this.areaBorderWeight;
        }

        if (this.backgroundZoomOffset !== undefined) {
            this.map.backgroundZoomOffset = this.backgroundZoomOffset;
        }

        if (this.regionSelectCallback !== undefined) {
            this.map.regionSelectCallback = this.regionSelectCallback;
        }

        if (this.levelSelectCallback !== undefined) {
            this.map.levelSelectCallback = this.levelSelectCallback;
        }

        if (this.showAttribution !== undefined) {
            this.map.showAttribution = this.showAttribution;
        }

        if (this.showInfoPanels !== undefined) {
            this.map.showInfoPanels = this.showInfoPanels;
        }

        if (this.showLabelPane !== undefined) {
            this.map.showLabelPane = this.showLabelPane;
        }

        if (this.showNames !== undefined) {
            this.map.showNames = this.showNames;
        }

        if (this.showScoreBar !== undefined) {
            this.map.showScoreBar = this.showScoreBar;
        }

        if (this.zoom !== undefined) {
            this.map.zoom = this.zoom;
        }

        signals.onLoad(this.drawMap);

    }

}
