var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("felsampel-map", {
        ref: "felsampelMap",
        staticStyle: { width: "820px", height: "600px" },
        attrs: {
          "background-zoom-offset": +1,
          "region-select-callback": _vm.selectRegion,
          "level-select-callback": _vm.selectLevelAndRegion,
          "show-attribution": true,
          "show-info-panels": true,
          "show-label-pane": true,
          "show-score-bar": true,
          zoom: 11.45
        }
      }),
      _vm._v(" "),
      _c("h2", [_vm._v("Regionen")]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.regions, function(regionDetails, regionId) {
          return _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "region", params: { regionId: regionId } }
                  }
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(regionDetails.long_label) +
                      "\n          "
                  )
                ]
              )
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.regionId
        ? _c("div", { staticClass: "level-selection" }, [
            _c("h4", [_vm._v("Lage")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.exposition,
                        expression: "exposition"
                      }
                    ],
                    attrs: { type: "radio", value: "t" },
                    domProps: { checked: _vm._q(_vm.exposition, "t") },
                    on: {
                      change: function($event) {
                        _vm.exposition = "t"
                      }
                    }
                  }),
                  _vm._v(" Exponiert")
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.exposition,
                        expression: "exposition"
                      }
                    ],
                    attrs: { type: "radio", value: "m" },
                    domProps: { checked: _vm._q(_vm.exposition, "m") },
                    on: {
                      change: function($event) {
                        _vm.exposition = "m"
                      }
                    }
                  }),
                  _vm._v(" Mittig")
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.exposition,
                        expression: "exposition"
                      }
                    ],
                    attrs: { type: "radio", value: "b" },
                    domProps: { checked: _vm._q(_vm.exposition, "b") },
                    on: {
                      change: function($event) {
                        _vm.exposition = "b"
                      }
                    }
                  }),
                  _vm._v(" Tallage")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shading,
                        expression: "shading"
                      }
                    ],
                    attrs: { type: "radio", value: "e" },
                    domProps: { checked: _vm._q(_vm.shading, "e") },
                    on: {
                      change: function($event) {
                        _vm.shading = "e"
                      }
                    }
                  }),
                  _vm._v(" Sonnig")
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shading,
                        expression: "shading"
                      }
                    ],
                    attrs: { type: "radio", value: "s" },
                    domProps: { checked: _vm._q(_vm.shading, "s") },
                    on: {
                      change: function($event) {
                        _vm.shading = "s"
                      }
                    }
                  }),
                  _vm._v(" Schattig")
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("a", { attrs: { id: "region" } }),
      _vm._v(" "),
      _vm.regionId === "0"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Schrammsteine und Schmilka")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Der großbankige Sandstein in den Schrammsteinen und im Schmilkaer Gebiet ist durch sehr heterogene\n          Festigkeiten gekennzeichnet. Neben der Lage gilt zu beachten, dass die teils sehr hohen Gipfel auch\n          in der vertikalen unterschiedlich abtrocknen. Während der Ausstiegsbereich schon lange trocken ist,\n          kann ein beschatteter Einstiegsbereich in brüchigem Gestein größere Feuchtigkeit und somit ein\n          erhöhtes Gefährdungspotenzial aufweisen.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId === "1"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Affensteine und kleiner Zschand")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Die verhältnismäßig griffarmen Affensteine sind im Allgemeinen ziemlich fest, was die\n          Wahrscheinlichkeit von Gesteinsausbrüchen minimiert. Feuchtigkeitsunterschiede treten hier eher in\n          den Rissen und Kaminen des vertikal gegliederten Sandsteins auf. Im kleinen Zschand hingegen ist die\n          Festigkeit durchschnittlich und heterogen, sodass Expositionsunterschiede wieder stärker ins Gewicht\n          fallen.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId === "2"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Wildensteiner Gebiet")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Das Wildensteiner Gebiet weist eine sehr heterogene Festigkeit auf. Während bedeutende Klettergipfel\n          wie die Lorenzsteine oder die Kleinsteinwand eher brüchig einzustufen sind, kann der Hausberg durch\n          vulkanische Einflüsse teilweise mit höheren Festigkeiten aufwarten. Ähnlich wie im kleinen Zschand\n          können Expositionsunterschiede die Bekletterbarkeit der Felsen nach Niederschlägen stark\n          beeinflussen.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId === "3"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Großer Zschand und Hinterhermsdorf")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Das Gestein des großen Zschands ist relativ brüchig. Neben Expositionsunterschieden im Tal ist hier\n          die erhöhte Anfälligkeit gegenüber Feuchtigkeit besonders zu beachten. Ähnlich wie im Rathener\n          Gebiet sollten Trockenzeiten unbedingt eingehalten werden! Das Hinterhermsdorfer Gebiet umfasst\n          Gipfel von zumeist geringer Höhe. Feuchtigkeitsunterschiede in dem griffarmen Sandstein treten in\n          erster Linie durch Exposition und Beschattung auf.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId === "4"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Rathen, Wehlen und Brand")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Der Rathener Sandstein ist besonders weich und brüchig. Diese Eigenschaften machen ihn anfällig\n          gegenüber Feuchtigkeit und Erosion. Die empfohlenen 2-3 Tage Trockenzeit nach Regenperioden sollten\n          daher unbedingt eingehalten werden!\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId === "5"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Gebiet der Steine")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Das linkselbische Gebiet der Steine inklusive Lilienstein zeichnet sich durch gute Gesteinsqualität\n          und die exponierte Lage auf den Ebenheiten aus. Der wesentlich festere Sandstein und die große\n          Angriffsfläche für Wind lassen die Tafelberge nach Niederschlägen schneller trocknen als die\n          rechtselbischen Gebiete.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId === "6"
        ? _c("div", { staticClass: "region-details" }, [
            _c("h2", [_vm._v("Bielatal")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          In Sachen Festigkeit hat der Labiatussandstein im Bielatal die beste Qualität der gesamten\n          Sächsischen Schweiz vorzuweisen. Aufgrund dieser Eigenschaft sind die hiesigen Gipfel nach\n          Niederschlagsereignissen früher bekletterbar. Zu beachten sind allerdings die\n          Expositionsunterschiede beiderseits des Tales. So kann sich die Feuchtigkeit auf der Westseite\n          deutlich länger halten als auf der Ostseite.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.regionId
        ? _c(
            "div",
            [
              _c("div", { staticClass: "region-situation" }, [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "shady-side-cell level-cell" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                _vm.shading = "s"
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                              " +
                                _vm._s(_vm.expositionNames[_vm.exposition]) +
                                " / " +
                                _vm._s(_vm.shadingNames["s"]) +
                                "\n                          "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "summit-cell", attrs: { rowspan: "2" } },
                        [
                          _c("felsampel-summit", {
                            ref: "felsampelBottomSignal",
                            attrs: {
                              level: _vm.exposition,
                              "on-click": _vm.selectLevel,
                              "region-id": _vm.regionId
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "exposed-side-cell level-cell" },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  _vm.shading = "e"
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                              " +
                                  _vm._s(_vm.expositionNames[_vm.exposition]) +
                                  " / " +
                                  _vm._s(_vm.shadingNames["e"]) +
                                  "\n                          "
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "shady-side-cell description-cell" },
                        [
                          _c("felsampel-region-level-description", {
                            attrs: {
                              level: _vm.exposition + "s",
                              "region-id": _vm.regionId
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "exposed-side-cell description-cell" },
                        [
                          _c("felsampel-region-level-description", {
                            attrs: {
                              level: _vm.exposition + "e",
                              "region-id": _vm.regionId
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("a", { attrs: { id: "prognosis" } }),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.expositionNames[_vm.exposition]) +
                    " / " +
                    _vm._s(_vm.shadingNames[_vm.shading])
                )
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Prognose")]),
              _vm._v(" "),
              _c("felsampel-prognosis", {
                ref: "felsampelPrognosis",
                attrs: { "region-id": _vm.regionId, "level-id": _vm.levelId }
              }),
              _vm._v(" "),
              _c(
                "h4",
                {
                  staticClass: "clickable",
                  on: {
                    click: function($event) {
                      _vm.showHourlyHistory = !_vm.showHourlyHistory
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.showHourlyHistory ? "▼" : "▶") +
                      "\n          Stündlicher Verlauf\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.showHourlyHistory
                ? _c(
                    "div",
                    { staticClass: "felsampel-chart-container" },
                    [
                      _c("felsampel-history", {
                        attrs: {
                          period: _vm.Periods.hourly,
                          "region-id": _vm.regionId,
                          "level-id": _vm.levelId
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h4",
                {
                  staticClass: "clickable",
                  on: {
                    click: function($event) {
                      _vm.showDailyHistory = !_vm.showDailyHistory
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.showDailyHistory ? "▼" : "▶") +
                      "\n          Täglicher Verlauf\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.showDailyHistory
                ? _c(
                    "div",
                    { staticClass: "felsampel-chart-container" },
                    [
                      _c("felsampel-history", {
                        attrs: {
                          period: _vm.Periods.daily,
                          "region-id": _vm.regionId,
                          "level-id": _vm.levelId
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }