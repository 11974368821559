




import "../style/prognosis.scss";

import {Component, Prop, Vue, Watch} from "vue-property-decorator";

import {FelsampelPrognosis} from "../lib/prognosis";
import {IRegionList} from "../lib/regions";
import {ISignalLevels, signals} from "../lib/signals";

@Component
export default class PrognosisComponent extends Vue {

    @Prop({type: String}) public regionId!: keyof IRegionList;
    @Prop({type: String}) public levelId!: keyof ISignalLevels;

    private prognosis!: FelsampelPrognosis;

    @Watch("regionId")
    @Watch("levelId")
    public drawPrognosis() {
        this.prognosis.clear();
        this.prognosis.draw(this.regionId, this.levelId);
    }

    private mounted() {
        this.prognosis = new FelsampelPrognosis(this.$el as HTMLElement);
        this.prognosis.showPrognosisLabel = false;
        signals.onLoad(this.drawPrognosis);
    }

}
