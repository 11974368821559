




import {Component, Prop, Vue} from "vue-property-decorator";
import {getScoreDescription} from "../lib/signals"

@Component
export default class ScoreDescriptionComponent extends Vue {

    @Prop({type: Number}) public score!: number;

    private getScoreDescription(score: number): string {
        return getScoreDescription(score / 10);
    }

}
