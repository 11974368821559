import {Vue} from "vue-property-decorator";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import RouterComponent from "./components/router.vue";
import AppComponent from "./components/app.vue";
import HistoryComponent from "./components/history.vue";
import MapComponent from "./components/map.vue";
import PrognosisComponent from "./components/prognosis.vue";
import SummitComponent from "./components/summit.vue";
import UserReportComponent from "./components/userReport.vue";
import WindroseComponent from "./components/windrose.vue";
import LastUpdateComponent from "./components/lastUpdate.vue";
import RegionLevelDescriptionComponent from "./components/regionLevelDescription.vue";
import ScoreDescriptionComponent from "./components/scoreDescription.vue";

// moment.js
// tslint:disable-next-line:no-var-requires
require("moment/locale/de");
import * as moment from "moment";
moment.locale("de");

import "./style/index.scss";
import "normalize.css";

// register components
Vue.component("felsampel-map", MapComponent);
Vue.component("felsampel-history", HistoryComponent);
Vue.component("felsampel-prognosis", PrognosisComponent);
Vue.component("felsampel-summit", SummitComponent);
Vue.component("felsampel-last-update", LastUpdateComponent);
Vue.component("felsampel-region-level-description", RegionLevelDescriptionComponent);
Vue.component("felsampel-user-report", UserReportComponent);
Vue.component("felsampel-windrose", WindroseComponent);
Vue.component("felsampel-score-description", ScoreDescriptionComponent);

const router = new VueRouter({
    routes: [
        {
            name: "region",
            path: "/region-:regionId?/:levelId?/:section?",
            component: AppComponent,
            props: true,
        },
        {
            name: "overview",
            path: "",
            component: AppComponent,
        },
    ],
    scrollBehavior(to) {
        if (to.params.section) {
            return {selector: `#${to.params.section}`};
        } else if (to.fullPath === "/") {
            return {x: 0, y: 0};
        }
    },
})

// tslint:disable-next-line:no-unused-expression
window.addEventListener('DOMContentLoaded', () => {
    new RouterComponent({router}).$mount("#felsampel-router");
});
